<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
    :persistent="loading"
  >
    <template  v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        small text
        v-on="on"
        :disabled="loading"
      >
        Interweave Pools
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Interweave Pools</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="window">
          <v-window-item :key="0">
            <div class="title">
              Select Pools & Courts
            </div>
            <v-autocomplete
              :items="pools"
              v-model="selectedPools"
              item-text="name"
              return-object
              label="Pools"
              chips small-chips deletable-chips
              hint="Select the pools you would like to interweave"
              persistent-hint
              multiple
              color="color3"
              item-color="color3"
            ></v-autocomplete>
            <v-combobox
              v-if="false"
              v-model="courts"
              label="Enter the courts to use"
              multiple
            ></v-combobox>
            <v-text-field
              label="Courts"
              hint="Enter the courts to use seperated by a comma"
              v-model="courts"
              persistent-hint
            ></v-text-field>
            <div class="text-end">
              <v-fab-transition>
                <v-btn
                  :key="`fab_${selectedPools.length > 0 && courtList.length > 0}`"
                  fab
                  color="success white--text"
                  @click.stop="window++"
                  :disabled="!(selectedPools.length > 0 && courtList.length > 0)"
                >
                  <v-icon>fas fa-caret-right</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </v-window-item>
          <v-window-item :key="1">
            <div class="title">
              Review
            </div>
            <div class="subtitle-1">
              Interweave {{selectedPools.length}} pools ({{ selectedPools.map(m => m.name) | formatArray }})
              onto {{courtList.length}} courts ({{ courtList | formatArray }}) ?
            </div>
            <div class="text-center pt-3">
              <v-btn
                color="success white--text"
                class="mr-3"
                :loading="loading"
                @click.stop="save"
              >Yes</v-btn>
              <v-btn
                color="error white--text"
                class="mr-3"
                @click.stop="window--"
                :disabled="loading"
              >No</v-btn>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'

export default {
  props: ['flight'],
  data () {
    return {
      dialog: false,
      window: 0,
      selectedPools: [],
      courts: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    pools () {
      if (!this.tournament) return []

      return flatten(this.tournament.publicDivisions.map(division => {
        return division.rounds.map(day => {
          return day.flights.map(f => {
            return f.pools.map(p => {
              return {
                id: p.id,
                name: `${division.name}|${day.name}| pool: ${p.name}`
              }
            })
          })
        })
      }))
    },
    courtList () {
      return this.courts ? this.courts.split(',') : []
    },
    dto () {
      return {
        poolIds: this.selectedPools.map(m => m.id),
        courts: this.courtList
      }
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$VBL.division.interweavePools(this.dto)
        .then(r => { this.dialog = false })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    loading: function (val) {
      this.$emit('loading-change', val)
    }
  }
}
</script>
